import moment from "moment";
import template from "lodash/template";
import Vue from "vue";


export const bindTemplate = function (val, args) {
  return template(val, { interpolate: /{{([\s\S]+?)}}/g })(args);
};

export const errorFunction = function (err, tableName) {
  console.log(err);
  
  const toast = Vue.$toast

  if (err.response.data.msg) {
    let msg = err.response.data.msg;

    if (msg) {
      return toast.error(msg);
    } else {
      return toast.error("Erro inesperado no servidor");
    }
  } else if (err.response.data.message) {
    if (err.response.data.message.includes("Erro exclusão:")) {
      if (tableName) {
        return toast.error(`Dado pertence a tela: ${tableName}`);
      } else {
        let table = err.response.data.message.split(" ").pop().slice(0, -1);

        return toast.error(`Dado pertence a tabela: ${table}`);
      }
    } else if (err.response.data.errno == 1406) {
      let column = err.response.data.message.split("'")[1];

      return toast.error(
        `Quantidade de caracteres acima do perdido para a coluna: ${column}`
      );
    } else if (err.response.data.message.includes("Duplicate entry")) {
      if (tableName) {
        return toast.error(`Dado já existe em: ${tableName}`);
      } else {
        let bidIndex = err.response.data.message.indexOf("bid_");
        let length = err.response.data.message.length;
        let sliced = err.response.data.message.slice(bidIndex, length);
        let dot = sliced.indexOf(".");
        let view = sliced.slice(4, dot);

        return toast.error(`Dado já existe em: ${view}`);
      }
    } else {
      return toast.error("Erro inesperado no servidor");
    }
  } else {
    return toast.error("Erro inesperado no servidor");
  }
};

export const filters = {
  dateFilter(value) {
    if (Vue.prototype.$vuetify.framework.lang.current === "pt") {
      moment.locale("pt-br");
    } else {
      moment.locale(Vue.prototype.$vuetify.framework.lang.current);
    }

    return moment.utc(value).format("L LT");
  },

  floatFilter(value) {
    if (Vue.prototype.$vuetify.framework.lang.current === "pt") {
      // return parseFloat(value).toFixed(2)
      return parseFloat(value).toFixed(2).toString().replace(".", ",");
    } else {
      return parseFloat(value).toFixed(2);
    }
  },

  cpfCnpjFilter(value) {
    let _value = value.replace(/[^\d]/g, "");

    if (_value.length <= 11) {
      return _value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      return _value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
  },

  zipCodeFilter(value) {
    let _value = value.replace(/[^\d]/g, "");

    return _value.replace(/(\d{5})(\d{3})/, "$1-$2");
  },
};

export const checkPermissions = function (permissions, path) {
  const userPermissions = {
    crudPermission: true,
    createPermission: true,
    updatePermission: true,
    deletePermission: true,
  };

  path === "/proposals/proposal-extra-costs"
    ? (path = "/proposals/proposal-product-actual-costs")
    : false;

  path = path.split("/")[2].replaceAll("-", "_").toUpperCase();

  const crudPermission = `CRUD_${path}`;

  const foundCrud = permissions.find((p) => p === crudPermission);
  if (foundCrud) {
    return userPermissions;
  } else {
    userPermissions.crudPermission = false;
  }

  const createPermission = `CREATE_${path}`;
  const foundCreate = permissions.find((p) => p === createPermission);
  if (!foundCreate) {
    userPermissions.createPermission = false;
  }

  const updatePermission = `UPDATE_${path}`;
  const foundUpdate = permissions.find((p) => p === updatePermission);
  if (!foundUpdate) {
    userPermissions.updatePermission = false;
  }

  const deletePermission = `DELETE_${path}`;
  const foundDelete = permissions.find((p) => p === deletePermission);
  if (!foundDelete) {
    userPermissions.deletePermission = false;
  }

  return userPermissions;
};

export function formatCurrency(value) {
  const formattedValue = parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return `R$ ${formattedValue}`;
}

export default {
  bindTemplate,
  errorFunction,
  filters,
  checkPermissions,
};
