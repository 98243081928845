const configs = {
  id: 1,
  url: "poc.atlas",
  system: "POC",
  module: "ZNAP_SECURITY",
  language: "pt",
  port: 80,
  theme: "poc.js",
  env: "prod",
};

export default configs;
