// import AccessProfile from '../components/Security/accessProfile'

export default [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      requireAuth: false,
      redirectHomeIfLogged: true,
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    path: "/security/",
    name: "Security",
    component: () =>
      import(/* webpackChunkName: "security" */ "../views/Security.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "user",
        name: "user",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "../components/Security/users.vue"
          ),
      },
      {
        path: "group",
        name: "group",
        component: () =>
          import(
            /* webpackChunkName: "group" */ "../components/Security/userGroups.vue"
          ),
      },
      {
        path: "group-transaction",
        name: "group-transaction",
        component: () =>
          import(
            /* webpackChunkName: "groupTransaction" */ "../components/Security/accessProfile.vue"
          ),
      },
      {
        path: "user-group",
        name: "user-group",
        component: () =>
          import(
            /* webpackChunkName: "userGroup" */ "../components/Security/userProfile.vue"
          ),
      },
      {
        path: "user-log-access",
        name: "user-log-access",
        component: () =>
          import(
            /* webpackChunkName: "userLogAccess" */ "../components/Security/accessReports.vue"
          ),
      },
      {
        path: "transaction",
        name: "transaction",
        component: () =>
          import(
            /* webpackChunkName: "transaction" */ "../components/Security/Transaction.vue"
          ),
      },
    ],
  },
  {
    path: "/masterdata/",
    name: "MasterData",
    component: () =>
      import(/* webpackChunkName: "masterdata" */ "../views/MasterData.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "omie-supplier",
        name: "omie-supplier",
        component: () =>
          import(
            /* webpackChunkName: "omie-supplier" */ "../components/MasterData/vendors.vue"
          ),
      },
      {
        path: "technicians",
        name: "technicians",
        component: () =>
          import(
            /* webpackChunkName: "technicians" */ "../components/MasterData/technicians.vue"
          ),
      },
      {
        path: "omie-product",
        name: "omie-product",
        component: () =>
          import(
            /* webpackChunkName: "omie-product" */ "../components/MasterData/products.vue"
          ),
      },
      {
        path: "product-family",
        name: "product-family",
        component: () =>
          import(
            /* webpackChunkName: "product-family" */ "../components/MasterData/productGroups.vue"
          ),
      },
      {
        path: "omie-services",
        name: "omie-services",
        component: () =>
          import(
            /* webpackChunkName: "omie-services" */ "../components/MasterData/services.vue"
          ),
      },
      {
        path: "services-type",
        name: "services-type",
        component: () =>
          import(
            /* webpackChunkName: "services-type" */ "../components/MasterData/serviceTypes.vue"
          ),
      },
      {
        path: "omie-customer",
        name: "omie-customer",
        component: () =>
          import(
            /* webpackChunkName: "omie-customer" */ "../components/MasterData/customers.vue"
          ),
      },
      {
        path: "omie-customer-supplier-origin-company",
        name: "omie-customer-supplier-origin-company",
        component: () =>
          import(
            /* webpackChunkName: "omie-customer-supplier-origin-company" */ "../components/MasterData/customerOriginCompany.vue"
          ),
      },
      {
        path: "omie-contact",
        name: "omie-contact",
        component: () =>
          import(
            /* webpackChunkName: "omie-contact" */ "../components/MasterData/contacts.vue"
          ),
      },
      {
        path: "omie-salesman",
        name: "omie-salesman",
        component: () =>
          import(
            /* webpackChunkName: "omie-salesman" */ "../components/MasterData/sellers.vue"
          ),
      },
      {
        path: "omie-buyer",
        name: "omie-buyer",
        component: () =>
          import(
            /* webpackChunkName: "omie-buyers" */ "../components/MasterData/buyers.vue"
          ),
      },
      {
        path: "omie-salesman-origin-company",
        name: "omie-salesman-origin-company",
        component: () =>
          import(
            /* webpackChunkName: "omie-salesman-origin-company" */ "../components/MasterData/salesmanOriginCompany.vue"
          ),
      },
      {
        path: "company",
        name: "company",
        component: () =>
          import(
            /* webpackChunkName: "company" */ "../components/MasterData/company.vue"
          ),
      },
      {
        path: "resource-type",
        name: "resource-type",
        component: () =>
          import(
            /* webpackChunkName: "resource-type" */ "../components/MasterData/resourceType.vue"
          ),
      },
      {
        path: "item-category",
        name: "item-category",
        component: () =>
          import(
            /* webpackChunkName: "item-category" */ "../components/MasterData/itemCategory.vue"
          ),
      },
      // {
      //   path: 'payment-terms',
      //   name: 'payment-terms',
      //   component: () => import(/* webpackChunkName: "payment-terms" */ '../components/MasterData/paymentConditions.vue')
      // },
      {
        path: "unit-measurement",
        name: "unit-measurement",
        component: () =>
          import(
            /* webpackChunkName: "unit-measurement" */ "../components/MasterData/unitOfMeasurement.vue"
          ),
      },
      {
        path: "proposal-type",
        name: "proposal-type",
        component: () =>
          import(
            /* webpackChunkName: "proposal-type" */ "../components/MasterData/proposalTypes.vue"
          ),
      },
      {
        path: "clause-type",
        name: "clause-type",
        component: () =>
          import(
            /* webpackChunkName: "clause-type" */ "../components/MasterData/termTypes.vue"
          ),
      },
      {
        path: "status-type",
        name: "status-type",
        component: () =>
          import(
            /* webpackChunkName: "status-type" */ "../components/MasterData/statusTypes.vue"
          ),
      },
      {
        path: "omie-product-integration",
        name: "omie-product-integration",
        component: () =>
          import(
            /* webpackChunkName: "omie-product-integration" */ "../components/MasterData/omieProductIntegration.vue"
          ),
      },
      {
        path: "omie-product-family-integration",
        name: "omie-product-family-integration",
        component: () =>
          import(
            /* webpackChunkName: "omie-product-family-integration" */ "../components/MasterData/omieProductFamilyIntegration.vue"
          ),
      },
      {
        path: "omie-customer-supplier-integration",
        name: "omie-customer-supplier-integration",
        component: () =>
          import(
            /* webpackChunkName: "omie-customer-supplier-integration" */ "../components/MasterData/omieCustomerSupplierIntegration.vue"
          ),
      },
    ],
  },
  {
    path: "/proposals",
    name: "Proposals",
    component: () =>
      import(/* webpackChunkName: "proposals" */ "../views/Proposals.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "clause",
        name: "clause",
        component: () =>
          import(
            /* webpackChunkName: "clause" */ "../components/Proposals/defaultTerms.vue"
          ),
      },
      {
        path: "proposal",
        name: "proposal",
        component: () =>
          import(
            /* webpackChunkName: "proposal" */ "../components/Proposals/proposals.vue"
          ),
      },
      {
        path: "proposal-clause",
        name: "proposal-clause",
        component: () =>
          import(
            /* webpackChunkName: "proposal-clause" */ "../components/Proposals/proposalClause.vue"
          ),
      },
      {
        path: "proposal-service-costs",
        name: "proposal-service-costs",
        component: () =>
          import(
            /* webpackChunkName: "proposal-service-costs" */ "../components/Proposals/proposalServiceCost.vue"
          ),
      },
      {
        path: "proposal-product-costs",
        name: "proposal-product-costs",
        component: () =>
          import(
            /* webpackChunkName: "proposal-product-costs" */ "../components/Proposals/proposalProductCost.vue"
          ),
      },
      {
        path: "proposal-extra-costs",
        name: "proposal-extra-costs",
        component: () =>
          import(
            /* webpackChunkName: "proposal-extra-costs" */ "../components/Proposals/proposalExtraCost.vue"
          ),
      },
      {
        path: "proposal-extra-costs/:id",
        name: "proposal-extra-costs-id",
        component: () =>
          import(
            /* webpackChunkName: "proposal-extra-costs-id" */ "../components/Proposals/ExtraCosts.vue"
          ),
      },
      {
        path: "proposal-status",
        name: "proposal-status",
        component: () =>
          import(
            /* webpackChunkName: "proposal-status" */ "../components/Proposals/proposalStatus.vue"
          ),
      },
      {
        path: "proposal-budget-open",
        name: "proposal-budget-open",
        component: () =>
          import(
            /* webpackChunkName: "proposal-budget-open" */ "../components/Proposals/proposalBudgetOpen.vue"
          ),
      },
      {
        path: "proposal-budget-closed",
        name: "proposal-budget-closed",
        component: () =>
          import(
            /* webpackChunkName: "proposal-budget-closed" */ "../components/Proposals/proposalBudgetClosed.vue"
          ),
      },
      // {
      //   path: 'omie-proposal-item',
      //   name: 'omie-proposal-item',
      //   component: () => import(/* webpackChunkName: "omie-proposal-item" */ '../components/Proposals/proposalItems.vue')
      // },
      {
        path: "editor",
        name: "editor",
        component: () =>
          import(
            /* webpackChunkName: "editor" */ "../components/Proposals/editor.vue"
          ),
      },
      {
        path: "service-status",
        name: "service-status",
        component: () =>
          import(
            /* webpackChunkName: "service-status" */ "../components/Proposals/serviceStatus.vue"
          ),
      },
      {
        path: "omie-proposal-integration",
        name: "omie-proposal-integration",
        component: () =>
          import(
            /* webpackChunkName: "omie-proposal-integration" */ "../components/Proposals/omieProposalIntegration.vue"
          ),
      },
      {
        path: "omie-proposal-invoicing-integration",
        name: "omie-proposal-invoicing-integration",
        component: () =>
          import(
            /* webpackChunkName: "omie-proposal-invoicing-integration" */ "../components/Proposals/omieProposalInvoicingIntegration.vue"
          ),
      },
      {
        path: "event",
        name: "event",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../components/Proposals/event.vue"
          ),
      },
      {
        path: "omie-event-integration",
        name: "omie-event-integration",
        component: () =>
          import(
            /* webpackChunkName: "omie-proposal-invoicing-integration" */ "../components/Proposals/omieEventIntegration.vue"
          ),
      },
      {
        path: "invoicing",
        name: "invoicing",
        component: () =>
          import(
            /* webpackChunkName: "omie-proposal-invoicing-integration" */ "../components/Proposals/invoicing.vue"
          ),
      },
      {
        path: "invoicing/table",
        name: "invoicing-table",
        component: () =>
          import(
            /* webpackChunkName: "invoicing-table" */ "../components/Proposals/proposalInvoicing.vue"
          ),
      },
      {
        path: "omie-invoicing-integration",
        name: "omie-invoicing-integration",
        component: () =>
          import(
            /* webpackChunkName: "omie-proposal-invoicing-integration" */ "../components/Proposals/omieInvoicingIntegration.vue"
          ),
      },
      {
        path: "proposal-report",
        name: "proposal-report",
        component: () =>
          import(
            /* webpackChunkName: "proposal-report" */ "../components/Proposals/ProposalReport.vue"
          ),
      },
      {
        path: "proposal-budget-report",
        name: "proposal-budget-report",
        component: () =>
          import(
            /* webpackChunkName: "proposal-budget-report" */ "../components/Proposals/ProposalBudgetReport.vue"
          ),
      },
      {
        path: "proposal-event-calendar",
        name: "proposal-event-calendar",
        component: () =>
          import(
            /* webpackChunkName: "proposal-event-calendar" */ "../components/Proposals/proposalEventCalendar.vue"
          ),
      },
    ],
  },
  {
    path: "/purchases",
    name: "Purchases",
    component: () =>
      import(/* webpackChunkName: "purchases" */ "../views/Purchases.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "omie-purchase-requisition",
        name: "omie-purchase-requisition",
        component: () =>
          import(
            /* webpackChunkName: "omie-purchase-requisition" */ "../components/Purchases/purchaseRequest.vue"
          ),
      },
      {
        path: "omie-service-requisition",
        name: "omie-service-requisition",
        component: () =>
          import(
            /* webpackChunkName: "omie-service-requisition" */ "../components/Purchases/serviceRequest.vue"
          ),
      },
      {
        path: "omie-purchase-requisition-item",
        name: "omie-purchase-requisition-item",
        component: () =>
          import(
            /* webpackChunkName: "omie-purchase-requisition-item" */ "../components/Purchases/purchaseItems.vue"
          ),
      },
      {
        path: "requisition-type",
        name: "requisition-type",
        component: () =>
          import(
            /* webpackChunkName: "requisition-type" */ "../components/Purchases/requisitionType.vue"
          ),
      },
      {
        path: "omie-service-requisition-item",
        name: "omie-service-requisition-item",
        component: () =>
          import(
            /* webpackChunkName: "omie-service-requisition-item" */ "../components/Purchases/serviceItems.vue"
          ),
      },
      {
        path: "requisition-status",
        name: "requisition-status",
        component: () =>
          import(
            /* webpackChunkName: "requisition-status" */ "../components/Purchases/purchaseStatus.vue"
          ),
      },
      {
        path: "omie-purchase-requisition-integration",
        name: "omie-purchase-requisition-integration",
        component: () =>
          import(
            /* webpackChunkName: "omie-purchase-requisition-integration" */ "../components/Purchases/omiePurchaseRequisitionIntegration.vue"
          ),
      },
      {
        path: "purchase-report",
        name: "purchase-report",
        component: () =>
          import(
            /* webpackChunkName: "purchase-report" */ "../components/Purchases/PurchaseReport.vue"
          ),
      },
      {
        path: "purchase-omie-report",
        name: "purchase-omie-report",
        component: () =>
          import(
            /* webpackChunkName: "purchase-report" */ "../components/Purchases/PurchaseOmieReport.vue"
          ),
      },
    ],
  },
  {
    path: "/approvalflow",
    name: "ApprovalFlow",
    component: () =>
      import(
        /* webpackChunkName: "approvalflow" */ "../views/ApprovalFlow.vue"
      ),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "approval-flow",
        name: "approval-flow",
        component: () =>
          import(
            /* webpackChunkName: "approval-flow" */ "../components/ApprovalFlow/approvalFlows.vue"
          ),
      },
      {
        path: "approval-flow-level",
        name: "approval-flow-level",
        component: () =>
          import(
            /* webpackChunkName: "approval-flow-level" */ "../components/ApprovalFlow/approvalFlowLevels.vue"
          ),
      },
      {
        path: "proposal-approval",
        name: "proposal-approval",
        component: () =>
          import(
            /* webpackChunkName: "proposal-approval" */ "../components/ApprovalFlow/proposalApproval.vue"
          ),
      },
      {
        path: "purchase-requisition-approval",
        name: "purchase-requisition-approval",
        component: () =>
          import(
            /* webpackChunkName: "purchase-requisition-approval" */ "../components/ApprovalFlow/purchaseRequisitionApproval.vue"
          ),
      },
      {
        path: "service-requisition-approval",
        name: "service-requisition-approval",
        component: () =>
          import(
            /* webpackChunkName: "service-requisition-approval" */ "../components/ApprovalFlow/serviceRequisitionApproval.vue"
          ),
      },
    ],
  },
  {
    path: "/approval-proposal",
    name: "approval-proposal",
    component: () =>
      import(
        /* webpackChunkName: "approvalproposal" */ "../views/ApprovalProposal.vue"
      ),
    meta: {
      requireAuth: false,
      redirectHomeIfLogged: false,
    },
  },
  {
    path: "/approval-requisition",
    name: "approval-requisition",
    component: () =>
      import(
        /* webpackChunkName: "approvalrequisition" */ "../views/ApprovalRequisition.vue"
      ),
    meta: {
      requireAuth: false,
      redirectHomeIfLogged: false,
    },
  },
  {
    path: "/approval-purchase-daily-report",
    name: "approval-purchase-daily-report",
    component: () =>
      import(
        /* webpackChunkName: "approvalpurchasedailyreport" */ "../views/ApprovalPurchaseDailyReport.vue"
      ),
    meta: {
      requireAuth: false,
      redirectHomeIfLogged: false,
    },
  },
  {
    path: "/stock",
    name: "Stock",
    component: () =>
      import(/* webpackChunkName: "stock" */ "../views/Stock.vue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    children: [
      {
        path: "stock-location",
        name: "stock-location",
        component: () =>
          import(
            /* webpackChunkName: "stock-location" */ "../components/Stock/stockLocation.vue"
          ),
      },
      {
        path: "stock-location-type",
        name: "stock-location-type",
        component: () =>
          import(
            /* webpackChunkName: "stock-location-type" */ "../components/Stock/stockLocationType.vue"
          ),
      },
      {
        path: "stock-status",
        name: "stock-status",
        component: () =>
          import(
            /* webpackChunkName: "stock-status" */ "../components/Stock/stockStatus.vue"
          ),
      },
      {
        path: "bom-product-unit",
        name: "bom-product-unit",
        component: () =>
          import(
            /* webpackChunkName: "bom-product-unit" */ "../components/Stock/bomProductUnit.vue"
          ),
      },
      {
        path: "bom-product",
        name: "bom-product",
        component: () =>
          import(
            /* webpackChunkName: "bom-product" */ "../components/Stock/bomProduct.vue"
          ),
      },
      {
        path: "bom-product-tree",
        name: "bom-product-tree",
        component: () =>
          import(
            /* webpackChunkName: "bom-product" */ "../components/Stock/bomProductTree.vue"
          ),
      },
      {
        path: "inventory-product-unit",
        name: "inventory-product-unit",
        component: () =>
          import(
            /* webpackChunkName: "inventory-product-unit" */ "../components/Stock/inventoryProductUnit.vue"
          ),
      },
      {
        path: "stock-movement",
        name: "stock-movement",
        component: () =>
          import(
            /* webpackChunkName: "stock-movement" */ "../components/Stock/stockMovement.vue"
          ),
      },
      {
        path: "product-unit-stock-movement",
        name: "product-unit-stock-movement",
        component: () =>
          import(
            /* webpackChunkName: "product-unit-stock-movement" */ "../components/Stock/productUnitStockMovement.vue"
          ),
      },
      {
        path: "bom-product-unit-tree-unit",
        name: "bom-product-unit-tree-unit",
        component: () =>
          import(
            /* webpackChunkName: "bom-product-unit-tree" */ "../components/Stock/bomProductUnitTreeUnit.vue"
          ),
      },
      {
        path: "stock-event-calendar",
        name: "stock-event-calendar",
        component: () =>
          import(
            /* webpackChunkName: "stock-event-calendar" */ "../components/Stock/stockEventCalendar.vue"
          ),
      },
      {
        path: "checklist-return-history",
        name: "checklist-return-history",
        component: () =>
          import(
            /* webpackChunkName: "checklist-return-history" */ "../components/Stock/checklistReturnHistory.vue"
          ),
      },
      {
        path: "stock-movement-approval",
        name: "stock-movement-approval",
        component: () =>
          import(
            /* webpackChunkName: "stock-movement-approval" */ "../components/Stock/stockMovementApproval.vue"
          ),
      },
      {
        path: "occurrence-type",
        name: "occurrence-type",
        component: () =>
          import(
            /* webpackChunkName: "occurrence-type" */ "../components/Stock/occurrenceType.vue"
          ),
      },
      {
        path: "stock-movement-approval-history",
        name: "stock-movement-approval-history",
        component: () =>
          import(
            /* webpackChunkName: "stock-movement-approval-history" */ "../components/Stock/stockMovementApprovalHistory.vue"
          ),
      },
      {
        path: "checklist-shipment",
        name: "checklist-shipment",
        component: () =>
          import(
            /* webpackChunkName: "checklist-shipment" */ "../components/Stock/checklistShipment.vue"
          ),
      },
      {
        path: "checklist-shipment/:id",
        name: "checklist-shipment-id",
        component: () =>
          import(
            /* webpackChunkName: "checklist-shipment-id" */ "../components/Stock/Checklist.vue"
          ),
        meta: {
          title: "Checklist de embarque - ESTOQUE",
        },
      },
      {
        path: "checklist-return",
        name: "checklist-return",
        component: () =>
          import(
            /* webpackChunkName: "checklist-return" */ "../components/Stock/checklistReturn.vue"
          ),
      },
      {
        path: "checklist-return/:id",
        name: "checklist-return-id",
        component: () =>
          import(
            /* webpackChunkName: "checklist-return-id" */ "../components/Stock/Checklist.vue"
          ),
        meta: {
          title: "Checklist de desembarque - ESTOQUE",
        },
      },
      {
        path: "occurrence",
        name: "occurrence",
        component: () =>
          import(
            /* webpackChunkName: "occurrence" */ "../components/Stock/occurrence.vue"
          ),
      },
      {
        path: "checklist-shipment-history",
        name: "checklist-shipment-history",
        component: () =>
          import(
            /* webpackChunkName: "checklist-shipment-history" */ "../components/Stock/checklistShipmentHistory.vue"
          ),
      },
      {
        path: "consult-status-stock",
        name: "consult-status-stock",
        component: () =>
          import(
            /* webpackChunkName: "consult-status-stock" */ "../components/Stock/consultStatusStock.vue"
          ),
      },
      {
        path: "consult-product",
        name: "consult-product",
        component: () =>
          import(
            /* webpackChunkName: "consult-product" */ "../components/Stock/consultProduct.vue"
          ),
      },
      {
        path: "stock-editor",
        name: "stock-editor",
        component: () =>
          import(
            /* webpackChunkName: "stock-editor" */ "../components/Stock/editor.vue"
          ),
      },
      {
        path: "operational-extra-products",
        name: "operational-extra-products",
        component: () =>
          import(
            /* webpackChunkName: "operational-extra-products" */ "../components/Stock/operationalExtraProducts.vue"
          ),
      },
      {
        path: "operational-extra-products/:id",
        name: "operational-extra-products-id",
        component: () =>
          import(
            /* webpackChunkName: "operational-extra-products-id" */ "../components/Stock/ExtraProducts.vue"
          ),
      },
      {
        path: "operational-extra-teams",
        name: "operational-extra-teams",
        component: () =>
          import(
            /* webpackChunkName: "operational-extra-teams" */ "../components/Stock/operationalExtraTeams.vue"
          ),
      },
      {
        path: "operational-extra-teams/:id",
        name: "operational-extra-teams-id",
        component: () =>
          import(
            /* webpackChunkName: "operational-extra-teams-id" */ "../components/Stock/ExtraTeams.vue"
          ),
      },
      {
        path: "operational-status",
        name: "operational-status",
        component: () =>
          import(
            /* webpackChunkName: "operational-status" */ "../components/Stock/operationalStatus.vue"
          ),
      },
      {
        path: "operational-status-history",
        name: "operational-status-history",
        component: () =>
          import(
            /* webpackChunkName: "operational-status-history" */ "../components/Stock/operationalStatusHistory.vue"
          ),
      },
      {
        path: "events-summary",
        name: "events-summary",
        component: () =>
          import(
            /* webpackChunkName: "events-summary" */ "../components/Stock/eventsSummary.vue"
          ),
      },
      {
        path: "list-editor",
        name: "list-editor",
        component: () =>
          import(
            /* webpackChunkName: "editor" */ "../components/Stock/components/ExtraProducts/ListEditor.vue"
          ),
      },
    ],
  },
];
