import getApiLocalPort from "./getApiLocalPort";

export default function setApiRoutes(Vue, systemUrl, env) {
  const domain = ".znaptech.com/";

  const setProtocol = (environment) => {
    return environment === "local" ? "http://" : "https://";
  };

  const setApi = () => {
    return `${systemUrl.toLowerCase()}.api.`;
  };

  const setRoute = (path) => {
    const protocol = setProtocol(env);

    if (env === "local") {
      const apiLocalPort = getApiLocalPort(path);
      return `${protocol}localhost:${apiLocalPort}/`;
    }

    const api = setApi();
    return `${protocol}${api}${path}${domain}`;
  };

  Vue.prototype.$ipUser = setRoute("user");
  Vue.prototype.$ipSecurity = setRoute("security");
  Vue.prototype.$ipMasterData = setRoute("master.data");
  Vue.prototype.$ipProposal = setRoute("proposal");
  Vue.prototype.$ipPurchase = setRoute("purchase");
  Vue.prototype.$ipApprovalFlow = setRoute("approval.flow");
  Vue.prototype.$ipIntegrationOmie = setRoute("integration.omie");
  Vue.prototype.$ipStock = setRoute("stock");
  Vue.prototype.$ipWebSocket = setRoute("websocket");
}
